import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/layouts/blogPost.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "pegasys-unveils-a-brand-new-staking-model-beginning-on-january-26th-2023",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#pegasys-unveils-a-brand-new-staking-model-beginning-on-january-26th-2023",
        "aria-label": "pegasys unveils a brand new staking model beginning on january 26th 2023 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Pegasys unveils a brand-new staking model beginning on January 26th, 2023.`}</h1>
    <p>{`Pegasys is rolling out a new staking contract, alongside a fee distribution model that replaces the old inflationary one. Furthermore, when you stake PSYS, the new model will give you rewards in PSYS based on the fees received from the protocol instead of putting more tokens in circulation. But why the change? The whole idea is to increase the demand for PSYS at the same time reduce the inflationary supply, further automating reward distribution and allowing incentives to prioritize the upcoming Syscoin Layer 2, Rollux.`}</p>
    <p>{`The first payout of the new staking rewards starts on Jan 26th. All users must un-stake their PSYS from the old staking and migrate to the new one to continue receiving rewards. The old inflationary stake contract is a discontinued staking product that will remain on the platform and can be accessed at any time by existing Pegasys Stakers, allowing users to unstake perpetually. However, it will not receive any yield from the platform anymore, so we recommend unstacking it as soon as you’re able to.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/eb25786fd16829d7fcbb26c8b5be0513/d61c2/banner2.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAADIklEQVQozwXBa09TBwCA4fNl80LLavFwbSnS9rRNKyClpZailAkIFSgMMmDYAmOYEqUzDrdBjEHMxGWikaGIBJCrG5BRAcNcCoShIzACXohLXJTsj7x7HiFCZkYemY5MYefgITsy0cGBmAz2xafxUbTE4awSNDledJmnEC0u9uucHEzOJiI5n4jEU+yPzuRjZRr7PjnKAbkJQamwcVjpQhnlJjImj8gkDxGGAmS2YiLdnxHja0bdcJ7k2ibiSmuQ53iRuyqQHytHJhUjU+cjjz1JpOhAEWVFUIlu1DGFqOK9aCQ/GmeQ2PI2EoI/ou8exjg4hXkyhDQwgXTnIYYfeom/0MGh0hZEVxNKqYIodSFiXC4xcScQ9PFF6BMqMGobSXG04/DfI/3KCNb+EIahaaSxKfT9Q2h7HqDt6Udz6z62vl853jWB2XcTnasFlVSFSlNMYmIBQoqmhAztWezmizScG2Fq5R1DG+8Y2H3PjZ1dOrd36Njc4trWNvV/hGleWGRw+w3jL/eYWvuHfN8dDGkBJEM1Om0JgsNQRo6lniJnG19fmKR9YJ1LC6+5u7RH3+oePcsf6F76QGB+i47513SGXtE2t0PLwt+0jrygoL4Pe1YrqRY/R43lCBm6UpwmP57sdhqaxmi//wL/403OPApTM71KZWiNb55u0fXnvwTDb/j03i8UPVqkbuYvam8t4Kn7GdeJVmypdRj0HgSjuhCrVIvL2kJeaRcXb6/w3cwONU/WsfSNYO57SPr4BI3rW5yenUPReR3n6AzB3zao75rldPVPWDMDWCxVRKudCOYjhZg0XtSqEtQWP2eCo/h6w1xZ3OX80itOTsxi6X2Ad3kV29g47skZAs82aB5doeLyMCZXI0kmL8lSEYr4YwgWbR5xogsx2k2SsYbc6m4qrz/hi8Flvl1+y9WX/9HwbI2yud/JeTxN1XyYQOg5+deGyT17k9SscxwxlRClsqNISEPQabIRlXbiYt1o9ZU48trI/fIuZTdm+Gp6g6vr77n0fJfg5ls+n1/C93QN31gYz+V+XMXfYzveiN7oQdTYUahT+B/6udScNgaVwgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "banner2",
            "title": "banner2",
            "src": "/static/eb25786fd16829d7fcbb26c8b5be0513/c1b63/banner2.png",
            "srcSet": ["/static/eb25786fd16829d7fcbb26c8b5be0513/5a46d/banner2.png 300w", "/static/eb25786fd16829d7fcbb26c8b5be0513/0a47e/banner2.png 600w", "/static/eb25786fd16829d7fcbb26c8b5be0513/c1b63/banner2.png 1200w", "/static/eb25786fd16829d7fcbb26c8b5be0513/d61c2/banner2.png 1800w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`The new `}{`$`}{`PSYS staking model allows you to `}<strong parentName="p">{`earn your share of Platform Trading Fees`}</strong>{`. For every swap on Pegasys, a 0.05% fee is charged and accrued by the protocol, this is converted into PSYS and then distributed to the PSYS Staking every few days. As a result, providing a buy-back from protocol fees creates buy pressure and a higher demand for PSYS, benefiting every token holder just by holding, and if you stake your PSYS, you can earn even more rewards.`}</p>
    <p>{`Users can Stake and unstake from Pegasys at any time. There is `}<strong parentName="p">{`however a deposit fee mechanism to prevent over-saturation. The fee will start at 1%`}</strong>{` and can scale up to 2% max, depending on the number of Pegasys already Staked, and it is taken from the amount of PSYS that you deposit into the Pool. All deposit fees taken will be distributed as rewards for the current members of the staking, increasing, even more, the rewards for those who are already staking.`}</p>
    <p>{`Stakers will receive rewards proportional to their share of the Staking Pool. Unlike the old model, rewards are no longer distributed linearly as they come from transaction fees and can vary according to Protocol volume. In this way, the `}<strong parentName="p">{`APR is no longer only restricted to the influence of the token amount in the Pool`}</strong>{`, and there may be `}<strong parentName="p">{`greater volatility in the APR over the period.`}</strong>{` Rewards will be available for claim every time a new user enters the pool and is charged the deposit fee or when the protocol fees are converted to PSYS and sent to the contract by our team every few days.`}</p>
    <p>{`The new staking model is available on our `}<a parentName="p" {...{
        "href": "https://v1.app.pegasys.fi/"
      }}>{`new UI`}</a>{` and you can learn more about how to migrate your PSYS to Pegasys’ new staking model in the video below.`}</p>
    <iframe width="660" height="315" src="https://www.youtube.com/embed/7tUTdh-yhdI" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
    <p>{`Feel free to head over to our `}<a parentName="p" {...{
        "href": "https://discord.com/invite/UzjWbWWERz"
      }}>{`Discord`}</a>{` server to share questions or recommendations you may have.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      